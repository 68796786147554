require("./prism-theme.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  console.info(getSavedScrollPosition(location))
  if (window.location.hash) {
    const elem = document.getElementById(window.location.hash.replace("#", ""))
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" })
      return false
    }
  }

  console.log("Scroll to top")
  document.body.scrollTo(0, 0)

  return false
}
